// create rule
.create-rule-container {
    width: 80%;
    height: auto;
    text-align: left;
    margin: 0 auto;
    &.manage-rule-container {
        width: 70%;
        padding: 2rem 0;
    }
    & .create-rule {
        & .create-rule-header {
            display: flex;
            // justify-content: space-between;
            align-items: flex-start;
            & .create-rule-close {
                width: 100%;
                & .create-rule-close-btn {
                    @include button-text;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            & .create-rule-title {
                width: 100%;
            }
        }
        & fieldset.action-container {
            width: 100%;
            height: auto;
            @include component-box-shadow;
            background-color: $colorBackground;
            border: none;
            outline: none;
            padding: 1.5rem;
            margin: 1rem 0;
            & legend {
                background: linear-gradient(90deg, #003da5 2.6%, #46db8c 100%);
                color: $colorTextInverted;
                border-radius: 0.25rem;
                padding: 0.24rem 1.5rem;
                font-size: 1.125rem;
            }
        }
        & .dynamicRuleEditor {
            border-radius: 8px;
            background-color: #f8fafb;
            width: 100%;
            height: auto;
            @include component-box-shadow;
            // background-color: $colorBackground;
            border: none;
            outline: none;
            padding: 1.5rem;
            margin: 1rem 0;
            & legend.customLegend {
                background: linear-gradient(90deg, #003da5 2.6%, #46db8c 100%);
                color: $colorTextInverted;
                border-radius: 0.25rem;
                padding: 0.24rem 1.5rem;
                font-size: 1.125rem;
            }
        }
        & .event,
        & .conditions,
        & .actions {
            display: flex;
            width: 90%;
            flex-direction: column;
            & .event-dropdown-container,
            & .condition-dropdown-container,
            & .action-dropdown-container {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                & div {
                    margin-right: 20px;
                }
                select,
                input {
                    margin-bottom: 1rem;
                    @include input-text;
                    min-width: 12rem;
                    max-width: 40%;
                    width: 12rem;
                    &[type='number'] {
                        @include input-number;
                    }
                }
                & select {
                    text-transform: capitalize;
                }
                h4 {
                    margin-bottom: 0.5rem;
                }
                & .delete-condition {
                    border: none;
                    outline: none;
                    background-color: transparent;
                    cursor: pointer;
                    margin-bottom: 1rem;
                }
            }
        }
        & .cta-container button {
            @include button-text;
        }
        & .rule-description-container {
            width: 100%;
            height: auto;
            background-color: $colorBackground;
            @include component-box-shadow;
            border: none;
            outline: none;
            padding: 1.5rem;
            margin: 1rem 0;
            display: flex;
            flex-direction: column;
            & label {
                font-size: 1.5rem;
                font-weight: bold;
            }
            & input {
                @include input-text;
            }
        }
        & .create-rule-cta-container {
            margin-top: 1.5rem;
            & button {
                margin-right: 1.5rem;
            }
            & .expire-rule {
                @include button-secondary;
            }
            & .save-rule {
                &.inactive {
                    @include button-disabled;
                }
                &.active {
                    @include button-secondary;
                }
            }
            & .publish-rule {
                &.inactive {
                    @include button-disabled;
                }
                &.active {
                    @include button-primary;
                }
            }
            & .delete-rule {
                &.inactive {
                    @include button-disabled;
                }
                &.active {
                    @include button-secondary;
                }
            }
        }

        .event-info-container {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }
        .info-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1rem;
            & img {
                width: 1rem;
                height: 1rem;
                display: none;
            }
        }
    }
}
